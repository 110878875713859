import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../i18n';
import Head from 'next/head';
import Btn from 'Components/Btn';
import Header from 'Components/Header';
import Footer from 'Components/Footer';
import Theme from '../../themes/Theme';
import { themes } from '../../themes/ThemeContext';
import stylesNordr from './PageNotFound.nordr.module.scss';
import stylesFolkhem from './PageNotFound.folkhem.module.scss';

const getStyles = (theme) => ({
        [themes.NORDR]: stylesNordr,
        [themes.FOLKHEM]: stylesFolkhem,
    }[theme]
);

class PageNotFound extends React.PureComponent {
    render() {
        const {
            hero, header, title, preamble, startPageLink, siteSettings, seo, subHeader
        } = this.props;
        const { objectsAndProjectsPage } = siteSettings

        const {
            htmlTitle,
            robots,
        } = seo;

        const { theme } = siteSettings;
        const styles = getStyles(theme);

        return (
            <>
                <Head>
                    <title>{htmlTitle}</title>
                    {!!robots && (
                        <meta name="robots" content={robots} />
                    )}

                    <meta name="msapplication-TileColor" content="#ffffff" />
                    <meta name="theme-color" content="#ffffff" />
                </Head>
                <Theme name={theme}>
                    <div className={`${styles['PageNotFound']} ${styles['PageNotFound--Wrapper']}`}>

                        {header && <Header {...header} breadcrumbs={[]} />}

                        <div className={styles['PageNotFound__Container']} role="document">

                            {!!hero?.image?.renditions && (
                                <div
                                    className={styles['PageNotFound__HeroImage']}
                                    style={{
                                        backgroundImage:
                                            'url(' +
                                            hero.image.renditions.hero.src +
                                            ')',
                                    }}></div>
                            )}


                            <div className={styles["PageNotFound__Content"]}>
                                <div>
                                    <span className={styles["PageNotFound__Superscript"]}>404</span>
                                    {title && (
                                        <h1 className={styles["PageNotFound__Headline"]}>{title}</h1>
                                    )}
                                    {subHeader && (
                                        <p className={styles["PageNotFound__SubHeader"]}>{subHeader}</p>
                                    )}
                                    {preamble && (
                                        <p className={styles["PageNotFound__Info"]}>{preamble}</p>
                                    )}
                                    <div className={styles["PageNotFound__Btns"]}>
                                        {objectsAndProjectsPage && (
                                            <Btn
                                                element="a"
                                                className={`Btn Btn--outline ${styles['PageNotFound__Btn']} ${styles['PageNotFound__Btn--White']}`}
                                                href={objectsAndProjectsPage}>
                                                {i18n.t('notFound.buttonFindApartment')}
                                            </Btn>
                                        )}
                                        {startPageLink && (
                                            <Btn
                                                element="a"
                                                className={`Btn Btn--outline ${styles['PageNotFound__Btn']}`}
                                                href={startPageLink.href}>
                                                {i18n.t('notFound.button')}
                                            </Btn>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Theme>
            </>
        );
    }
}

PageNotFound.propTypes = {
    header: PropTypes.object,
    title: PropTypes.string,
    preable: PropTypes.string,
    startPageLink: PropTypes.object,
    footer: PropTypes.object,
};

PageNotFound.defaultProps = {
    startPageLink: {
        href: '/',
        title: '',
    },
};

export default PageNotFound;
